<template>
  <v-app>
    <HeaderComponent />
    <router-view></router-view>
    <contactUs />
    <footerComponent />
  </v-app>
</template>

<script>
import contactUs from "@/components/contactUs.vue";
import footerComponent from "@/components/UI/footerComponent.vue";
import HeaderComponent from "@/components/UI/headerComponent.vue";
export default {
  name: "App",
  components: {
    contactUs,
    footerComponent,
    HeaderComponent
  },
};
</script>

<style>
@import "@/assets/style/common.css";
</style>
