<template>
  <v-dialog max-width="1080px" v-model="visibility" content-class="dialog">
    <div class="dialog-card">
      <div class="dialog-item dialog-item-text">
        <div class="dialog-item-text-title">{{ "your_choice" | localize }}</div>
        <div class="dialog-item-text-desc">
          {{ "your_choise_desc" | localize }}
        </div>
      </div>
      <div class="dialog-item dialog-item-contacts">
        <div class="dialog-item-contacts-close">
          <v-icon class="close" color="black" @click="visibility = false"
            >mdi-close</v-icon
          >
        </div>
        <div class="dialog-item-contacts-title">
          {{ "form_title" | localize }}
        </div>
        <div class="dialog-item-contacts-desc">
          {{ "form_desc" | localize }}
        </div>
        <v-form class="emailjs-form" @submit.prevent="onClick">
          <div class="mt-8">
            <v-col cols="12" class="pa-0 pb-4"
              ><v-text-field
                color="#33A440"
                :label="'name' | localize"
                outlined
                dense
                height="48px"
                maxLength="25"
                v-model="userForm.name"
                hide-details="auto"
                name="user_name"
                class="dialogTextField"
                :error-messages="nameErrors"
                style="border-radius: 10px"
              ></v-text-field
            ></v-col>
            <v-col cols="12" class="pa-0 pb-4"
              ><v-text-field
                color="#33A440"
                :label="'phone' | localize"
                maxLength="13"
                outlined
                dense
                height="48px"
                name="user_phone"
                v-model="userForm.phone"
                hide-details="auto"
                :error-messages="phoneErrors"
                class="dialogTextField"
                style="border-radius: 10px"
              ></v-text-field
            ></v-col>
            <v-col cols="12" class="pa-0 pb-4"
              ><v-text-field
                color="#33A440"
                label="Email"
                outlined
                dense
                height="48px"
                name="user_email"
                v-model="userForm.email"
                hide-details="auto"
                :error-messages="emailErrors"
                maxLength="50"
                class="dialogTextField"
                style="border-radius: 10px"
              ></v-text-field
            ></v-col>
            <v-col cols="12" class="pa-0"
              ><v-textarea
                color="#33A440"
                :label="'additionally' | localize"
                outlined
                dense
                name="user_addictions"
                hide-details
                no-resize
                height="100"
                v-model="userForm.addictions"
                maxLength="125"
                style="border-radius: 10px"
              ></v-textarea
            ></v-col>
          </div>
          <div class="d-flex align-center justify-center my-5">
            <button class="default-btn dialog-btn" type="submit">
              {{ "send_btn" | localize }}
            </button>
          </div>
        </v-form>
        <div class="dialog-item-img-container">
          <img src="../../assets/logo.png" alt="logo" />
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import { required, email } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import { mapGetters } from "vuex";
import emailjs from "@emailjs/browser";

export default {
  mixins: [validationMixin],
  data: () => ({
    userForm: {
      name: "",
      email: "",
      phone: "",
      addictions: "",
    },
    errors: {
      required_en: "Required!",
      required_ua: "Поле обов'язкове для заповлення!",
      email_ua: "Email має бути коректним!",
      email_en: "Email must be valid!",
    },
  }),
  props: {
    visible: {
      required: true,
      type: Boolean,
    },
  },
  validations: {
    userForm: {
      name: { required },
      email: { required, email },
      phone: { required },
    },
  },
  methods: {
    onClick() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        emailjs
          .sendForm("service_ef7pkzp", "template_g8sfs3o", ".emailjs-form", {
            publicKey: "koKvhebT7OHsLNFTh",
          })
          .then(
            () => {
              console.log("SUCCESS!");
              this.visibility = false;
              this.$emit("dialog-success");
            },
            (error) => {
              console.log("FAILED...", error);
            }
          );
      }
    },
  },
  computed: {
    ...mapGetters(["user"]),
    visibility: {
      get() {
        return this.visible;
      },
      set() {
        return this.$emit("close");
      },
    },
    nameErrors() {
      const errors = [];
      if (!this.$v.userForm.name.$dirty) {
        return errors;
      }
      if (!this.$v.userForm.name.required) {
        if (this.user.locale == "ua") {
          errors.push(this.errors.required_ua);
        } else {
          errors.push(this.errors.required_en);
        }
      }
      return errors;
    },
    phoneErrors() {
      const errors = [];
      if (!this.$v.userForm.phone.$dirty) {
        return errors;
      }
      if (!this.$v.userForm.phone.required) {
        if (this.user.locale == "ua") {
          errors.push(this.errors.required_ua);
        } else {
          errors.push(this.errors.required_en);
        }
      }
      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.userForm.email.$dirty) {
        return errors;
      }
      if (!this.$v.userForm.email.required) {
        if (this.user.locale == "ua") {
          errors.push(this.errors.required_ua);
        } else {
          errors.push(this.errors.required_en);
        }
      }
      if (!this.$v.userForm.email.email) {
        if (this.user.locale == "ua") {
          errors.push(this.errors.email_ua);
        } else {
          errors.push(this.errors.email_en);
        }
      }
      return errors;
    },
  },
};
</script>

<style lang="scss">
.dialogTextField {
  .v-input__control {
    .v-input__slot {
      .v-label {
        top: 14px;
      }
    }
  }
}
.dialog {
  box-shadow: none !important;
}
.dialog-card {
  height: 750px;
  display: flex;
  background: rgba(255, 255, 255, 0.6);
  justify-content: space-between;
  width: 100%;
}
.dialog-item {
  padding: 32px;
  max-width: 50%;
  width: 100%;
}
.dialog-item-text {
  background-image: url("../../assets/dialog-bg.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.dialog-item-text-title {
  color: #5f6662;
  text-align: center;
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  margin-top: 30px;
  line-height: normal;
}
.dialog-item-text-desc {
  color: #5f6662;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
}
.dialog-item-contacts {
  background: #fff;
}
.dialog-item-contacts-close {
  display: flex;
  justify-content: flex-end;
}
.dialog-item-contacts-title {
  color: var(--black, #1b1b1b);
  text-align: center;
  font-family: "MacPaw Fixel";
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 8px;
}
.dialog-item-contacts-desc {
  color: var(--black, #1b1b1b);
  text-align: center;
  font-family: "MacPaw Fixel";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 16px;
}
.dialog-item-contacts-link-container a {
  color: #1b1b1b !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-radius: 4px;
  border: 1px solid rgba(22, 71, 28, 0.1);
  text-decoration: none;
  margin-bottom: 12px;
  padding: 14px 2px;
}
.dialog-item-contacts-link-container a:hover {
  border-radius: 4px;
  border: 1px solid rgba(22, 71, 28, 0.2);
  background: rgba(22, 71, 28, 0.1);
  color: var(--G1, #16471c) !important;
}
.dialog-item-contacts__icon-list {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 36px;
  margin-top: 36px;
}
.dialog-item-contacts__icon-list a + a {
  margin-left: 20px;
}
.dialog-item-img-container {
  display: flex;
  justify-content: center;
}
.dialog-item-contacts-close .close:hover {
  color: rgba(22, 71, 28, 1) !important;
}
.dialog-btn {
  background: #33a440 !important;
  padding: 17px;
  width: 100%;
  font-size: 16px !important;
}
@media (max-width: 880px) {
  .dialog-item-img-container img {
    max-width: 200px;
  }
}
@media (max-width: 700px) {
  .dialog-item-text {
    display: none;
  }
  .dialog-item-contacts {
    backdrop-filter: none;
  }
  .dialog-item {
    max-width: 100%;
  }
}
@media (max-width: 400px) {
  .dialog-item-contacts-title {
    font-size: 28px;
    line-height: normal;
  }
  .dialog-item-contacts-desc {
    font-size: 14px;
  }
}
</style>
