import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      dark: {
        background: "rgb(51, 51, 51)",
      },
      light: {
        background: "white",
      },
    },
  },
});
