import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "mainPage",
    component: () => import("@/views/mainPage.vue"),
  },
  {
    path: "/photos",
    name: "photosPage",
    component: () => import("@/views/photosPage.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});
export default router;
