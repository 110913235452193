<template>
  <header class="header">
    <div class="container">
      <div class="header__inner">
        <div
          class="header-logo"
          @click="pushToMainPage()"
          style="cursor: pointer"
        >
          <div><img src="../../assets/logo.png" alt="logo" /></div>
        </div>
        <nav class="list">
          <div class="list__item">
            <span @click="scrollToId('benefits')">{{
              "our_advantages" | localize
            }}</span>
          </div>
          <div class="list__item">
            <span @click="scrollToId('products')">{{
              "our_products" | localize
            }}</span>
          </div>
          <div class="list__item">
            <span @click="scrollToId('aboutUs')">{{
              "about_us" | localize
            }}</span>
          </div>
          <div class="list__item">
            <span @click="scrollToId('productsGallery')">{{
              "gallery" | localize
            }}</span>
          </div>
        </nav>
        <div class="d-flex align-center ml-3">
          <v-menu
            v-model="languageChange"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
            class="text-caption"
          >
            <template v-slot:activator="{ on, attrs }">
              <div
                class="d-flex align-center justify-center"
                v-bind="attrs"
                v-on="on"
              >
                <span class="mr-2 d-flex align-center"
                  ><svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <g clip-path="url(#clip0_508_158)">
                      <path
                        d="M11.5546 0.0562501C10.3546 0.50625 9.22956 2.69062 8.57799 5.84531C8.43268 6.54375 8.43268 6.55781 8.51237 6.73125C8.65768 7.04062 8.55924 7.03125 11.9999 7.03125C15.4405 7.03125 15.3421 7.04062 15.4874 6.73125C15.5671 6.55781 15.5671 6.54375 15.4217 5.84531C15.028 3.93281 14.4374 2.31094 13.7483 1.24687C13.4764 0.825 12.9374 0.290625 12.6327 0.140625C12.3655 0.0093751 11.7983 -0.0374999 11.5546 0.0562501Z"
                        fill="#FAFAFA"
                      />
                      <path
                        d="M8.22667 0.58125C7.06886 0.960937 5.80324 1.62656 4.74386 2.42344C3.49699 3.36094 2.18917 4.85156 1.55167 6.06562C1.35011 6.44531 1.33605 6.58594 1.47199 6.80625C1.60792 7.02656 1.6548 7.03125 4.40167 7.03125C7.18605 7.03125 7.22355 7.02656 7.37355 6.7875C7.41574 6.73125 7.50011 6.38906 7.57042 6.02812C7.90323 4.26562 8.45636 2.54062 9.0423 1.43437C9.24386 1.05937 9.23917 0.839062 9.02823 0.628125C8.83605 0.435937 8.70011 0.426562 8.22667 0.58125Z"
                        fill="#FAFAFA"
                      />
                      <path
                        d="M14.9723 0.628125C14.7614 0.839062 14.7567 1.05937 14.9583 1.43437C15.5442 2.54062 16.0973 4.26562 16.4302 6.02812C16.5005 6.38906 16.5848 6.73125 16.627 6.7875C16.777 7.02656 16.8145 7.03125 19.5989 7.03125C22.3458 7.03125 22.3927 7.02656 22.5286 6.80625C22.6645 6.58594 22.6505 6.44531 22.4489 6.06562C21.638 4.51875 19.9505 2.77031 18.3052 1.76719C17.2645 1.13437 15.7036 0.46875 15.2583 0.46875C15.1786 0.46875 15.0755 0.525 14.9723 0.628125Z"
                        fill="#FAFAFA"
                      />
                      <path
                        d="M0.810987 8.03438C0.623487 8.11875 0.585987 8.18907 0.421925 8.77969C-0.0608877 10.5047 -0.135888 12.5109 0.210987 14.3438C0.314112 14.8781 0.5438 15.6938 0.632862 15.825C0.764112 16.0313 0.895362 16.0359 3.97036 16.0219L6.89068 16.0078L7.01255 15.8859C7.19068 15.7078 7.20474 15.5438 7.12974 14.6766C7.04068 13.6219 7.04068 10.3781 7.12974 9.32344C7.20474 8.45625 7.19068 8.29219 7.01255 8.11407L6.89068 7.99219L3.92349 7.98282C1.47661 7.97344 0.93755 7.98282 0.810987 8.03438Z"
                        fill="#FAFAFA"
                      />
                      <path
                        d="M8.4474 8.03906C8.34896 8.08125 8.26927 8.16093 8.23177 8.25937C8.14271 8.47031 8.0724 9.25781 8.00677 10.7437C7.92708 12.4922 8.05365 15.3141 8.23177 15.7406C8.35365 16.0406 8.2599 16.0312 12.0005 16.0312C15.7411 16.0312 15.6474 16.0406 15.7693 15.7406C15.8583 15.5297 15.9286 14.7422 15.9943 13.2562C16.074 11.5078 15.9474 8.68594 15.7693 8.25937C15.6474 7.95937 15.7411 7.96875 11.9911 7.96875C9.1599 7.97344 8.57865 7.98281 8.4474 8.03906Z"
                        fill="#FAFAFA"
                      />
                      <path
                        d="M17.1757 8.00156C17.0351 8.02969 16.871 8.21719 16.8335 8.39532C16.8148 8.47969 16.8335 8.89688 16.871 9.32344C16.9601 10.3781 16.9601 13.6219 16.871 14.6766C16.796 15.5438 16.8101 15.7078 16.9882 15.8859L17.1101 16.0078L20.0304 16.0219C23.1054 16.0359 23.2367 16.0313 23.3679 15.825C23.457 15.6938 23.6867 14.8781 23.7898 14.3438C24.0757 12.8391 24.0757 11.1609 23.7898 9.65625C23.7007 9.19219 23.4663 8.34844 23.3913 8.20313C23.2695 7.97344 23.2226 7.96875 20.1476 7.97344C18.5679 7.97813 17.2273 7.99219 17.1757 8.00156Z"
                        fill="#FAFAFA"
                      />
                      <path
                        d="M1.66452 17.0391C1.50046 17.1187 1.43014 17.2172 1.38796 17.4187C1.35983 17.5359 1.39264 17.6391 1.55202 17.9344C2.36296 19.4812 4.05046 21.2297 5.69577 22.2328C6.73639 22.8656 8.29733 23.5312 8.74264 23.5312C8.82233 23.5312 8.92546 23.475 9.02858 23.3719C9.23952 23.1609 9.24421 22.9406 9.04264 22.5656C8.45671 21.4594 7.90358 19.7344 7.57077 17.9719C7.50046 17.6109 7.41608 17.2687 7.37389 17.2125C7.22389 16.9734 7.18639 16.9687 4.39733 16.9687C2.19889 16.9687 1.78171 16.9828 1.66452 17.0391Z"
                        fill="#FAFAFA"
                      />
                      <path
                        d="M8.73737 17.0344C8.65299 17.0719 8.55924 17.1656 8.51237 17.2688C8.43268 17.4422 8.43268 17.4563 8.57799 18.1547C9.08893 20.6297 9.88581 22.5141 10.7999 23.4141C11.2592 23.8688 11.4889 23.9766 11.9999 23.9766C12.5108 23.9766 12.7405 23.8688 13.1999 23.4141C14.1139 22.5141 14.9108 20.6297 15.4217 18.1547C15.5671 17.4563 15.5671 17.4422 15.4874 17.2688C15.3421 16.9594 15.4405 16.9688 11.9905 16.9688C9.49674 16.9734 8.85456 16.9828 8.73737 17.0344Z"
                        fill="#FAFAFA"
                      />
                      <path
                        d="M16.8427 17.0391C16.6223 17.1328 16.5661 17.2594 16.4302 17.9719C16.0973 19.7344 15.5442 21.4594 14.9583 22.5656C14.7567 22.9406 14.7614 23.1609 14.9723 23.3719C15.0755 23.475 15.1786 23.5312 15.2583 23.5312C15.7036 23.5312 17.2645 22.8656 18.3052 22.2328C19.9505 21.2297 21.638 19.4812 22.4489 17.9344C22.6505 17.5547 22.6645 17.4141 22.5286 17.1937C22.3927 16.9734 22.3458 16.9687 19.5848 16.9687C17.4848 16.9734 16.9645 16.9828 16.8427 17.0391Z"
                        fill="#FAFAFA"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_508_158">
                        <rect width="24" height="24" fill="white" />
                      </clipPath>
                    </defs></svg
                ></span>
                <span class="language-text">{{
                  user.locale == "ua" ? "Укр" : "Eng"
                }}</span>
              </div>
            </template>
            <v-card width="120px" class="py-0">
              <v-list
                class="pa-2"
                style="
                  border-radius: 10px;
                  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.1);
                "
              >
                <v-list-item
                  style="border-radius: 10px"
                  class="justify-center mb-2 px-0"
                  @click="changeUserLocale('ua')"
                >
                  <span
                    :class="
                      user.locale == 'ua'
                        ? 'activeLanguage'
                        : 'inactiveLanguage'
                    "
                    class="language"
                  >
                    Укр
                  </span>
                </v-list-item>
                <v-list-item
                  style="border-radius: 10px"
                  class="justify-center px-0"
                  @click="changeUserLocale('en')"
                >
                  <span
                    :class="
                      user.locale == 'en'
                        ? 'activeLanguage'
                        : 'inactiveLanguage'
                    "
                    class="language"
                  >
                    Eng
                  </span>
                </v-list-item>
              </v-list>
            </v-card>
          </v-menu>
          <button class="custom-btn ml-5" @click="dialogVisible = true">
            {{ "contact_us" | localize }}
          </button>
          <v-btn
            icon
            x-large
            @click="mobileDrawer = !mobileDrawer"
            class="toggle-btn"
            ><v-icon color="rgba(250, 250, 250, 1)">mdi-menu</v-icon></v-btn
          >
        </div>
      </div>
      <dialogComponent
        :visible="dialogVisible"
        @close="dialogVisible = false"
        v-if="dialogVisible"
      />
      <navigationDrawer
        :visible="mobileDrawer"
        @close="mobileDrawer = false"
        v-if="mobileDrawer"
      />
    </div>
  </header>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import dialogComponent from "@/components/dialogs/dialogComponent.vue";
import navigationDrawer from "@/components/dialogs/navigationDrawer.vue";
export default {
  data: () => ({
    dialogVisible: false,
    mobileDrawer: false,
    languageChange: false,
  }),
  components: {
    dialogComponent,
    navigationDrawer,
  },
  methods: {
    async scrollToId(id) {
      if (this.$route.path != "/photos") {
        const element = document.getElementById(id);
        const scrollOffset = 120;
        const targetPosition = element.offsetTop;
        window.scrollTo({
          top: targetPosition - scrollOffset,
          behavior: "smooth",
        });
      } else {
        await this.$router.push("/");
        const element = document.getElementById(id);
        const scrollOffset = 120;
        const targetPosition = element.offsetTop;
        window.scrollTo({
          top: targetPosition - scrollOffset,
        });
      }
    },
    async pushToMainPage() {
      if ((await this.$route.path) != "/") {
        this.$router.push("/");
        window.scrollTo(0, 0);
      } else {
        window.scrollTo(0, 0);
      }
    },
    ...mapActions(["updateInfo"]),
    changeUserLocale(locale) {
      this.updateInfo({
        locale: locale,
      });
      this.languageChange = false;
    },
  },
  computed: {
    ...mapGetters(["user"]),
  },
};
</script>
<style scoped>
.header {
  position: sticky;
  top: 0;
  z-index: 20;
}
.container {
  max-width: 1920px;
  padding: 0;
}
.header__inner {
  padding: 20px 130px;
  background: linear-gradient(90deg, #15471d 0%, #33ad47 100%);
  min-height: 110px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header-btn {
  display: flex;
  align-items: center;
}
.custom-btn {
  color: var(--black, #1b1b1b);
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 12px 20px;
  border-radius: 4px;
  background: var(--white, #fafafa);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  transition: all 0.4s;
  width: 220px;
}
.custom-btn:hover {
  background: #d1d1d1;
}
.list {
  display: flex;
  align-items: center;
}
.list__item + .list__item {
  margin-left: 32px;
}
.list__item span {
  text-decoration: none;
  color: var(--white, #fafafa);
  font-family: "MacPaw Fixel";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  transition: all 0.4s;
  cursor: pointer;
}
.list__item span:hover {
  opacity: 0.7;
}
.toggle-btn {
  display: none;
}
@media (max-width: 1260px) {
  .header__inner {
    padding: 10px 60px;
  }
}
@media (max-width: 1120px) {
  .header__inner {
    padding: 10px 20px;
  }
}
@media (max-width: 1040px) {
  .list {
    display: none;
  }
  .custom-btn {
    display: none;
  }
  .toggle-btn {
    display: inline-block;
  }
}
</style>
